import React, {useState, useEffect} from "react";
import countryAPI from "../services/countryAPI";

const ContactPage = (props) => {
    const [countries, setCountries] = useState([])

    const fetchCountry = async () => {
        //Recupere les donnes et tire par ordre alphabetique
        try {
            const data = await countryAPI.getAllCountries();
            data.sort(function(a,b) {
                var nameA = a.name.common
                var nameB = b.name.common
                if(nameA < nameB){
                    return -1;
                }
                if(nameA > nameB){
                    return 1;
                }
                return 0;
            })
            setCountries(data)
        }catch(error)
        {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchCountry()
    },[])

    return ( 
        <>
            <div className="contactUs">
                <div className="left"><span>Contact us</span></div>
                <div className="right"><span>Have any questions, bugs ?</span></div>
            </div>
            <div className="formAndInfo">
                <form>
                    <div className="rowFAI">
                        <label className="labelRadio" htmlFor="choice">What can I do for you ?</label>
                        <div className="radioGroup">
                            <div className="inputContainer">
                                <input type="radio" name="choice" id="question"/>
                                <div className="radioTitle">
                                    <label htmlFor="question">Question</label>
                                </div>
                            </div>
                            <div className="inputContainer">
                                <input type="radio" name="choice" id="bugs" />
                                <div className="radioTitle">
                                    <label htmlFor="bugs">Bug</label>
                                </div>
                            </div>
                            <div className="inputContainer">
                                <input type="radio" name="choice" id="additionnalInfo" />
                                <div className="radioTitle">
                                    <label htmlFor="additionnalInfo">Additional infos</label>
                                </div>
                            </div>
                            <div className="inputContainer">
                                <input type="radio" name="choice" id="other" />
                                <div className="radioTitle">
                                    <label htmlFor="other">Other</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rowFAI">
                        <label htmlFor="countryChoice" className="labelRadio">On any Country ?</label>
                        <select className="selectForm" name="countryChoice" id="countryChoice">
                            <option value="none">None</option>
                            {countries.map((country) => (
                                <option value={country.name.common} key={country.name.common}>{country.flag} {country.name.common}</option>
                            ))}
                        </select>
                    </div>
                    <div className="rowFAI">
                        <div className="contenaireNameEmail">
                            <div className="name">
                                <input type="text" name="name" id="name" placeholder="Your name"/>
                            </div>
                            <div className="email">
                                <input type="email" name="email" id="email" placeholder="Your e-mail"/>
                            </div>
                        </div>
                    </div>
                    <div className="rowFAI">
                        <label htmlFor="area" className="labelRadio">Your message</label>
                        <textarea name="area" id="area"></textarea>
                    </div>
                    <div className="rowFAI">
                        <div className="wrapperSubmit">
                            <input type="submit" value="SEND"/>
                        </div>
                    </div>
                </form>
                <div className="infoFAI">
                    <div className="textFAI">
                        <p>Do you have questions about our platform, encountered a bug, or need additional information about a country? Don't hesitate to reach out! Our team is here to assist you with any inquiries you may have. Whether you're seeking clarification on country data, reporting an issue, or simply want to provide feedback, we welcome your communication.</p>

                        <p>Feel free to contact us via email or fill out the contact form below. We strive to respond promptly to all inquiries and appreciate your engagement with our platform.</p>
                    </div>
                    <div className="socialNetwork">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default ContactPage;