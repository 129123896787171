const Footer = () => {
    return ( 
        <>
            <div className="footer">
                <span>APICountry &copy; Copyright Antoine Baut, EPSE</span>
            </div>
        </>
     );
}
 
export default Footer;